//mui
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import Paper from '@mui/material/Paper'

//libraries
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
//components
import {
  errorMessage,
  executeWithTryCatch,
  gettingCompanyId,
  hostStringMapper,
  hostStringSplitter,
  requiredLabel,
  sanitizeExceptAlphabets,
  SanitizeSpecialCharacters,
} from '../../../../../helpers/HelperFunctions'
import { serviceAddressSchema } from '../../../../../validations/ServicesValidation'
import {
  BorderBox,
  SpaceBetweenBox,
} from '../../../../../common/styles/styled-components/StyledContainers'
import { TitleText } from '../../../../../common/styles/styled-components/StyledTypography'
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../../common/styles/styled-components/StyledTables'
//const
import {
  HOST_MODES,
  IP_ADDRESS_PATTERN,
  IP_PATTERN,
  NETMASK_ITEMS,
  ORDER_STATUS_ENUM,
  SERVICES,
  TOAST_MESSAGES,
} from '../../../../../helpers/Constants'
//assets
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import backbtn from '../../../../../assets/icons/Back Button.svg'
import nextbtn from '../../../../../assets/icons/Next Button.svg'

//redux
import { useCommonRedux } from '../../../../../store/middlewares/CommonRedux'
import { serviceSliceState } from '../../../../../store/slice/serviceSlice'
import {
  formDetailsState,
  setSignupAddressId,
  setSIPDetails,
  StepBackward,
  StepForward,
} from '../../../../../store/slice/stepperSlice'
import { useEffect } from 'react'
import { getCountries, getStates } from '../../../../../api/get/getApi'
import {
  setCountriesList,
  setSkeletonLoading,
  setStatesList,
} from '../../../../../store/slice/commonSlice'
import { useToastContext } from '../../../../../App'
import { CompanyDraft } from '../../../../../api/post/postApi'

const initialFormikValues = {
  sipAddressName: '',
  address1: '',
  address2: '',
  country: 'USA',
  state: '',
  city: '',
  zipCode: '',
  hosts: [{ address: '', netMask: '', error: false }],
}

const SIPDetails = () => {
  const { toast } = useToastContext()
  const { dispatch, state: commonState } = useCommonRedux()
  const state = useSelector(serviceSliceState)
  const formState = useSelector(formDetailsState)

  //hooks
  const {
    errors,
    isValid,
    values,
    touched,
    dirty,
    setErrors,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true, // Reinitialize form values when initialValues change
    initialValues: initialFormikValues,
    validationSchema: serviceAddressSchema,
  })

  useEffect(() => {
    executeWithTryCatch(getCountriesList, handleError)

    //persisting sip form data
    if (Object.keys(formState.sipDetails || {})?.length) {
      resetForm({
        values: {
          address1: formState.sipDetails?.sipAddress?.line1,
          address2: formState.sipDetails?.sipAddress?.line2,
          sipAddressName: formState.sipDetails?.sipAddress?.sipAddressName,
          state: formState.sipDetails?.sipAddress?.state,
          country: formState.sipDetails?.sipAddress?.country,
          zipCode: formState.sipDetails?.sipAddress?.zipCode,
          city: formState.sipDetails?.sipAddress?.city,
          hosts: formState.sipDetails?.sipTrunkHost
            ? hostStringSplitter(formState.sipDetails?.sipTrunkHost)
            : values.hosts,
        },
      })
    }
  }, [])

  const getCountriesList = async () => {
    dispatch(setSkeletonLoading(true))
    const {
      data: { result },
    } = await getCountries()
    dispatch(setCountriesList(result))
    await getStatesList()
    dispatch(setSkeletonLoading(false))
  }

  const getStatesList = async () => {
    const {
      data: { result },
    } = await getStates(values.country || 'USA')
    dispatch(setStatesList(result))
  }

  const handleError = (error) => {
    dispatch(setSkeletonLoading(false))
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
  }

  const addNewHost = () => {
    setFieldValue('hosts', [
      ...values.hosts,
      { address: '', netMask: '', error: false },
    ])
  }

  const removeHost = (key) => {
    const hosts = [...values.hosts]
    hosts.splice(key, 1)
    setFieldValue('hosts', hosts)
  }

  const handleAddress = (e, index) => {
    const { value } = e?.target
    const hosts = [...values.hosts]
    hosts[index] = {
      ...hosts[index],
      address: value,
      error: !IP_PATTERN.test(value),
    }
    setFieldValue('hosts', hosts)
  }

  const handleNetMask = (e, index) => {
    const { value } = e?.target
    const hosts = [...values.hosts]
    hosts[index] = {
      ...hosts[index],
      netMask: value,
    }
    setFieldValue('hosts', hosts)
  }

  const handleSubmitSIPDetails = async () => {
    try {
      if (
        values.hosts?.length === 0 ||
        values.hosts[0].address === '' ||
        values.hosts[0].netMask === ''
      ) {
        toast.showToast(TOAST_MESSAGES.ProvideAtleastOneConfiguration, 'error')
        return
      }
      if (
        values.hosts?.length > 0 &&
        values.hosts[0].address !== '' &&
        values.hosts[0].netMask !== '' &&
        values.sipAddressName &&
        values.address1 &&
        values.city &&
        values.state &&
        values.zipCode &&
        values.country
      ) {
        const token = localStorage.getItem('sd_cp.jwt:tkn')
        const config = { headers: { Authorization: `Bearer ${token}` } }
        const formValues = { ...values }
        const request = {}
        request.companyId = gettingCompanyId()
        request.step = formState.step + 1
        request.isSubmitted = false
        request.isRevParent = formState.isRevParent
        request.isAltigenBilling = formState.isAltigenBilling
        request.service = SERVICES.SipTrunking
        request.termsAndConditionsFileData =
          formState.termsAndConditionBase64String
        request.isTermsAndConditionsAccepted =
          formState.isTermsAndConditionsAccepted
        request.companyDetail = formState.companyDetail
        request.accountDetail = {
          email: formState.accountDetail?.email,
          firstName: formState.accountDetail?.firstName,
          lastName: formState.accountDetail?.lastName,
          phone: formState.accountDetail?.phone,
          extNumber: formState.accountDetail?.extNumber,
        }
        request.billingContact = {
          firstName: formState.billing?.firstName,
          lastName: formState.billing?.lastName,
          phone: formState.billing?.phone,
          phoneExtension: formState.billing?.phoneExtension,
          mobile: formState.billing?.mobile,
          fax: formState.billing?.fax,
          email: formState.billing?.email,
        }
        request.billingAddress = {
          companyName: formState.companyDetail.companyName,
          state: formState.billing.state,
          city: formState.billing.city,
          line1: formState.billing.line1,
          line2: formState.billing.line2,
          country: formState.billing.country || 'USA',
          zip: formState.billing.zip,
        }
        request.billingType = {
          billingType: formState.billingType.billingType,
        }
        request.paymentMethod = {
          creditCard: {
            ...formState.creditCard,
            cvv: String(formState.creditCard?.cvv),
            cardNumber: String(formState.creditCard?.cardNumber),
          },
          bankAccount: formState.bankAccount,
        }
        request.paymentType = formState.paymentType || 1
        request.appearance = formState.appearance
        request.sipDetails = {
          sipAddress: {
            revAddressId: formState.sipDetails?.sipAddress?.revAddressId,
            companyId: gettingCompanyId(),
            companyName: formState.companyDetail?.companyName,
            addressType: 1,
            sipAddressName: formValues.sipAddressName,
            line1: formValues.address1,
            line2: formValues.address2,
            city: formValues.city,
            zipCode: formValues.zipCode,
            country: formValues.country,
            state: formValues.state,
            sipAddressId:
              formState.sipSignupAddressId ||
              formState.sipDetails?.sipAddress?.sipAddressId ||
              0,
            revCustomerId: formState.sipDetails?.sipAddress?.revCustomerId || 0,
          },
          sipTrunkHost: hostStringMapper(formValues.hosts),
        }
        const response = await CompanyDraft(request, config) // API call
        const successMessage =
          response?.data?.message || TOAST_MESSAGES.DataSaved
        toast.showToast(successMessage, 'success')
        dispatch(setSIPDetails(response.data.result?.sipDetails))
        dispatch(
          setSignupAddressId(
            response.data.result?.sipDetails?.sipAddress?.sipAddressId
          )
        )
        dispatch(StepForward())
      }
    } catch (error) {
      const errorMessage = normalizeApiResponse(error)?.message
      toast.showToast(errorMessage, 'error')
    }
  }

  return (
    <Grid container spacing={6} padding={2} marginTop={1}>
      {/* SIP Address */}
      <form>
        <BorderBox>
          <TitleText color="primary">SIP Service Address</TitleText>
          <Grid container sx={{ mb: 5 }}>
            {/* Sip Address Name*/}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('Service Address Name')}
              <TextField
                id="SipAddressName"
                name="sipAddressName"
                onChange={handleChange}
                value={values.sipAddressName}
                error={errors.sipAddressName}
                size="small"
                fullWidth
                required
              />
              {errors.sipAddressName && errorMessage(errors.sipAddressName)}
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            {/* Address 1 */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('Address Line 1')}
              <TextField
                id="Address1"
                name="address1"
                onChange={(e) => {
                  setFieldValue(
                    'address1',
                    SanitizeSpecialCharacters(e.target.value)
                  )
                }}
                value={values.address1}
                error={errors.address1}
                size="small"
                maxRows={3}
                fullWidth
              />
              {errors.address1 && errorMessage(errors.address1)}
            </Grid>
            {/* Address 2 */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('Address  Line 2')}
              <TextField
                id="Address2"
                name="address2"
                onChange={handleChange}
                value={values.address2}
                error={errors.address2}
                size="small"
                maxRows={3}
                fullWidth
              />
              {(errors.address2 || touched.address2) &&
                errorMessage(errors.address2)}
            </Grid>
            {/* City */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('City')}
              <TextField
                id="City"
                name="city"
                onChange={(e) => {
                  setFieldValue('city', sanitizeExceptAlphabets(e.target.value))
                }}
                value={values.city}
                error={errors.city}
                size="small"
                fullWidth
              />

              {(errors.city || touched.city) && errorMessage(errors.city)}
            </Grid>

            {/* State*/}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('State')}
              <Select
                fullWidth
                value={values.state}
                id="service-address-state"
                labelId="service-address-city"
                error={errors.state}
                onChange={(e) => {
                  setFieldValue('state', e.target.value)
                }}
                size="small"
              >
                {commonState.statesList?.map((_state, key) => (
                  <MenuItem key={_state.code} value={_state.code}>
                    {_state.name}
                  </MenuItem>
                ))}
              </Select>
              {(errors.state || touched.state) && errorMessage(errors.state)}
            </Grid>

            {/* zipCode*/}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('Zip')}
              <TextField
                id="ZipCode"
                name="zipCode"
                type="number"
                onChange={(e) => {
                  if (e.target.value?.length <= 6) {
                    setFieldValue('zipCode', e.target.value)
                  }
                }}
                value={values.zipCode}
                error={errors.zipCode}
                size="small"
                fullWidth
              />
              {(errors.zipCode || touched.zipCode) &&
                errorMessage(errors.zipCode)}
            </Grid>

            {/* Country */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {requiredLabel('Country')}
              <Select
                fullWidth
                value={values.country}
                id="service-address-country"
                labelId="service-address-country"
                error={errors.country}
                defaultValue="USA"
                onChange={(e) => {
                  setFieldValue('country', e.target.value)
                }}
                size="small"
              >
                {commonState.countriesList?.map((_country, key) => (
                  <MenuItem key={_country.code} value={_country.code}>
                    {_country.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.country && errorMessage(errors.country)}
            </Grid>
          </Grid>
        </BorderBox>

        {/* ----------------------------------------------- */}
        <BorderBox>
          <SpaceBetweenBox>
            <TitleText color="primary">
              SIP Configuration Details <span style={{ color: 'red' }}>*</span>
            </TitleText>
            <Tooltip title="Add New SIP Configuration">
              <IconButton color="success" onClick={addNewHost}>
                <AddCircleOutlineOutlinedIcon color="success" />
              </IconButton>
            </Tooltip>
          </SpaceBetweenBox>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Address</StyledTableCell>
                  <StyledTableCell align="center">Netmask</StyledTableCell>
                  <StyledTableCell align="center"> </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.hosts?.map((host, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell align="center" component="th" scope="row">
                      <TextField
                        size="small"
                        value={host.address}
                        onChange={(e) => handleAddress(e, key)}
                        error={host.error}
                        helperText={host.error && 'Invalid IP Address'}
                        disabled={
                          state.selectedOrderService?.ordered ||
                          state.selectedOrderService?.orderStatus ==
                            ORDER_STATUS_ENUM.PendingCancellation
                        }
                        autoComplete="off"
                        InputProps={{
                          inputProps: {
                            pattern: IP_ADDRESS_PATTERN,
                          },
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Select
                        labelId="netMask-select-label"
                        id="netMask-select"
                        value={host.netMask}
                        onChange={(e) => handleNetMask(e, key)}
                        size="small"
                        sx={{ width: 100 }}
                        disabled={
                          state.selectedOrderService?.ordered ||
                          state.selectedOrderService?.orderStatus ==
                            ORDER_STATUS_ENUM.PendingCancellation
                        }
                        defaultValue="/32"
                      >
                        {NETMASK_ITEMS.map((netMask, key) => (
                          <MenuItem
                            key={key}
                            value={`/${netMask}`}
                          >{`/${netMask}`}</MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {!(
                        values.hosts.length === 1 ||
                        state.selectedOrderService?.ordered ||
                        state.selectedOrderService?.orderStatus ==
                          ORDER_STATUS_ENUM.PendingCancellation
                      ) && (
                        <IconButton
                          onClick={() => removeHost(key)}
                          color="error"
                        >
                          <DeleteOutlineIcon
                            color={
                              values.hosts.length > 1 ? 'error' : 'bordered'
                            }
                          />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </BorderBox>

        {/* Action Buttons */}
        <Grid item md={4} sm={8} xs={8}>
          <Stack direction="row" justifyContent="flex-start">
            <img
              src={backbtn}
              className="btn-hover-effect"
              height="92px"
              onClick={() => {
                dispatch(StepBackward())
              }}
              alt="next button"
            />
            <img
              src={nextbtn}
              className="btn-hover-effect"
              height="92px"
              onClick={handleSubmitSIPDetails}
              alt="next button"
            />
          </Stack>
        </Grid>
      </form>
    </Grid>
  )
}

export default SIPDetails
