import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  Button,
  Container,
  Grid,
  InputBase,
  Link,
  TableHead,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'

import { useEffect, useMemo, useState } from 'react'
import jwt_decode from 'jwt-decode'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { useLocation, useSearchParams } from 'react-router-dom'
import LoadingSection from '../../../common/components/loaders/loader'
import NoDataFoundCard from '../../../common/components/cards/noDataFoundCard'
import { invoiceBillingListGet } from '../../../api/get/getApi'
import { InvoiceDetails } from './InvoiceDetails'

import DownloadIcon from '@mui/icons-material/Download'
import { createRef } from 'react'
import { formatDate, getCompanyLevel, normalizeApiResponse } from '../../../helpers/HelperFunctions'
import { useToastContext } from '../../../App'

function InvoiceList(props) {
  const { toast } = useToastContext();
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [rowsList, setRowsList] = React.useState([])
  const [rowsFilteredList, setRowsFilteredList] = React.useState([])
  const [edited, setEdited] = React.useState('')
  const [details, setDetails] = React.useState('')
  const [showDetails, setShowDetails] = React.useState(true)
  const [loader, setLoader] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const [state, setState] = React.useState({
    right: false,
  })
  const pageTopRef = createRef()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  let inputHandler = (e) => {
    const lowerCase = e.target.value
    const filteredData = rowsFilteredList.filter((el) => {
      if (lowerCase === '') {
        return el
      } else {
        return el?.billId.toString().includes(lowerCase)
      }
    })
    setRowsList(filteredData)
    setPage(0)
  }
  const getList = (id) => {
    setLoader(true)
    invoiceBillingListGet(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setLoader(false)
        setRowsList(response.data.result)
        setRowsFilteredList(response.data.result)
      })
      .catch((error) => {
        setLoader(false)
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      })
  }
  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getList(companyId)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageTopRef.current.scrollTop = 0
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
    pageTopRef.current.scrollTop = 0
  }

  const getDetails = (details) => {
    setDetails(details)
    setShowDetails(false)
  }
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsList.length) : 0

  // const tableData = useMemo(()=> {
  //     return props?.array || []
  // }, [props?.array])

  useEffect(() => {
    getDecodeValue()
  }, [])
  return (
    <Container maxWidth="xl" className="Dash_main_container">
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Grid item xs={12}>
        {showDetails ? (
          <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  m: '8px 0',
                  display: 'flex',
                  alignItems: 'center',
                  width: 400,
                }}
              >
                {/*<IconButton sx={{ p: '10px' }} aria-label='menu'>*/}
                {/*  <MenuIcon />*/}
                {/*</IconButton>*/}
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  onChange={inputHandler}
                  inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
            <TableContainer component={Paper} ref={pageTopRef}>
              <Table
                stickyHeader
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead className="thead">
                  <TableRow>
                    <TableCell>Bill #</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Remaining Due</TableCell>
                  </TableRow>
                </TableHead>
                {loader ? (
                  <TableBody className="tbody">
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6}>
                        <SkeletonTableLoader></SkeletonTableLoader>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="tbody">
                    {(rowsPerPage > 0
                      ? rowsList?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : rowsList
                    ).map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell scope="row" onClick={() => getDetails(row)}>
                          <Link> {row.billId}</Link>
                        </TableCell>
                        <TableCell>
                          {formatDate(new Date(row.dueDate))}
                        </TableCell>
                        <TableCell>{row.invoiceAmount}</TableCell>
                        <TableCell>{row.amountDue}</TableCell>
                      </TableRow>
                    ))}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                    {rowsList.length === 0 ? (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} style={{ border: 'none' }}>
                          <h3
                            style={{
                              margin: '0px auto',
                              padding: '80px 0',

                              textAlign: 'center',
                            }}
                          >
                            <NoDataFoundCard
                              messages="No Invoice Found"
                              borderNone={true}
                            />
                          </h3>
                        </TableCell>
                      </TableRow>
                    ) : (
                      ''
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              component="div"
              count={rowsList?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        ) : (
          <InvoiceDetails
            setShowDetails={setShowDetails}
            details={details}
            billId={details?.billId}
            handleBack={() => {
              setShowDetails(true)
              getDecodeValue()
            }}
          />
        )}
      </Grid>
    </Container>
  )
}

export default InvoiceList
