import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  styled,
} from '@mui/material'
import * as React from 'react'
import {
  masterDetailsApi,
  masterProductsAllApi,
  masterProductsPostApi,
} from '../../../../api/master/masterAPI'
import { useEffect } from 'react'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader'
import jwt_decode from 'jwt-decode'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { useLocation, useSearchParams } from 'react-router-dom'
import { genericProductsAllApi } from '../../../../api/get/getApi'
import { useFormik } from 'formik'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useSelector } from 'react-redux'
import {
  settingsSliceState,
  setIsCurrentProductsUpdated,
} from '../../../../store/slice/settingsSlice'
import { AvailableServicesSkeleton } from '../../../../common/components/loaders/skeletons/SettingsSkeleton'
import {
  setAllProducts,
  setCurrentCompanyDetails,
  setSelectedProducts,
} from '../../../../store/slice/commonSlice'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import {
  getCompanyLevel,
  gettingCompanyId,
  normalizeApiResponse,
  removeDuplicates,
  stringAvatar,
} from '../../../../helpers/HelperFunctions'
import { useToastContext } from '../../../../App'
import sipLogo from '../../../../assets/sip-logo.png'
import maxucLogo from '../../../../assets/maxuc-logo.png'
import drLogo from '../../../../assets/dr-logo.png'
import {
  COMPANY_LEVELS,
  PRODUCT_NAMES,
  SERVICE_PRODUCT_CODES,
} from '../../../../helpers/Constants'
import { FlexBox } from '../../../../common/styles/styled-components/StyledContainers'

const StyledAvatar = styled(Avatar)(({ theme, width = 65, height = 65 }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: width,
  height: height,
  inlineSize: 'fit-content',
  padding: 8,
}))

function MasterServices(props) {
  const { dispatch, state } = useCommonRedux()
  const { toast } = useToastContext()
  const settingsState = useSelector(settingsSliceState)
  const [loader, setLoader] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [showEditButton, setShowEditButton] = React.useState(false)
  const [editLoader, setEditLoader] = React.useState(false)
  const [showParentId, setParentId] = React.useState()
  const [directCustomer, setDirectCustomer] = React.useState()
  const [token, setToken] = React.useState({})
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const companyId = gettingCompanyId()
  const currentLevel = getCompanyLevel(companyId, location.pathname.slice(1))

  useEffect(() => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    setToken(decoded)
    getServicesAllApi(companyId)
    setShowEditButton(!(currentLevel == 'master'))
    return () => {
      dispatch(setIsCurrentProductsUpdated(false))
    }
  }, [])

  const getServicesAllApi = (id) => {
    if (id) {
      setLoader(true)
      if (searchParams.get('company_id')) {
        if (currentLevel == 'serviceprovider') {
          getAllProducts(id, 'master')
        }
        if (currentLevel == 'reseller') {
          getAllProducts(id || gettingCompanyId(), 'serviceProvider')
        }
        if (currentLevel == 'customer') {
          if (directCustomer == false) {
            getAllProducts(id || gettingCompanyId(), 'reseller')
          } else {
            getAllProducts(id || gettingCompanyId(), 'serviceProvider') //get direct customer services
          }
        }
      } else {
        if (
          currentLevel == 'master' &&
          !settingsState.isCurrentProductsUpdated
        ) {
          setShowEditButton(false)
          masterProductsAllApi(companyId, currentLevel)
            .then((response) => {
              dispatch(setSelectedProducts(response.data.result)) //all products for master
              dispatch(setAllProducts(response.data.result))
              dispatch(setIsCurrentProductsUpdated(true))
              if (!state.currentCompanyDetails) {
                masterDetailsApi(id, currentLevel)
                  .then((response) => {
                    setParentId(response.data.result.parentId)
                    dispatch(setDetails(response.data.result))
                  })
                  .catch((error) => {
                    setLoader(false)
                    toast.showToast(normalizeApiResponse(error)?.message, 'error')
                  })
              } else {
                setParentId(state.currentCompanyDetails?.parentId)
                setLoader(false)
              }
              setLoader(false)
            })
            .catch((error) => {
              setLoader(false)
              setShowEditButton(false)
              toast.showToast(normalizeApiResponse(error)?.message, 'error')
            })
        } else if (currentLevel != 'master') {
          getAllProducts(id)
        } else {
          setLoader(false)
        }
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      companyId: '',
      products: [],
    },
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      const checkedValue = state.allProducts?.map((chec) => {
        if (chec.checked) {
          return chec.id
        }
      })
      const filtValue = checkedValue.filter((filt) => filt)
      data.products = filtValue
      if (decoded.company_level == 1) {
        masterProductsPostApi(
          data,
          getCompanyLevel(
            searchParams.get('company_id'),
            location.pathname.slice(1)
          )
        )
          .then((response) => {
            setSubmitLoader(false)
            setShowEdit(false)
            const products = state.allProducts.filter((o) =>
              response.data.result.products.some((t) => t === o.id)
            )
            dispatch(setSelectedProducts(products))
            dispatch(
              setCurrentCompanyDetails({
                ...state.currentCompanyDetails,
                products: products,
              })
            )
            toast.showToast(response.data.message, 'success')
          })
          .catch((error) => {
            setSubmitLoader(false)
            toast.showToast( normalizeApiResponse(error)?.message, 'error')
          })
      } else if (decoded.company_level == 2) {
        masterProductsPostApi(
          data,
          getCompanyLevel(
            searchParams.get('company_id'),
            location.pathname.slice(1)
          )
        )
          .then((response) => {
            setSubmitLoader(false)
            setShowEdit(false)
            const selectedProducts =
              state.allProducts?.filter((product) =>
                response.data.result?.products.some((o) => o === product.id)
              ) || []
            dispatch(setSelectedProducts(selectedProducts))
            dispatch(
              setCurrentCompanyDetails({
                ...state.currentCompanyDetails,
                products: selectedProducts,
              })
            )
            toast.showToast(response.data.message, 'success')
            // getDecodeValue()
          })
          .catch((error) => {
            setSubmitLoader(false)
            toast.showToast( normalizeApiResponse(error)?.message, 'error')
          })
      } else if (decoded.company_level == 3) {
        masterProductsPostApi(
          data,
          getCompanyLevel(
            searchParams.get('company_id'),
            location.pathname.slice(1)
          )
        )
          .then((response) => {
            setSubmitLoader(false)
            setShowEdit(false)
            const products = state.allProducts.filter((o) =>
              response.data.result.products.some((t) => t === o.id)
            )
            dispatch(setSelectedProducts(products))
            dispatch(
              setCurrentCompanyDetails({
                ...state.currentCompanyDetails,
                products: products,
              })
            )
            toast.showToast(response.data.message, 'success')
            getDecodeValue()
          })
          .catch((error) => {
            setSubmitLoader(false)
            toast.showToast( normalizeApiResponse(error)?.message, 'error')
          })
      } else if (decoded.company_level == 4) {
        masterProductsPostApi(data, 'customer')
          .then((response) => {
            setSubmitLoader(false)
            setShowEdit(false)
            const products = state.allProducts.filter((o) =>
              response.data.result.products.some((t) => t === o.id)
            )
            dispatch(setSelectedProducts(products))
            dispatch(
              setCurrentCompanyDetails({
                ...state.currentCompanyDetails,
                products: products,
              })
            )
            toast.showToast(response.data.message, 'success')
            getDecodeValue()
          })
          .catch((error) => {
            setSubmitLoader(false)
            toast.showToast( normalizeApiResponse(error)?.message, 'error')
          })
      }
    },
  })

  const handleEditServices = async (isEdit) => {
    const mappedProducts = await mapProducts(
      state.allProducts || state.selectedProducts || [],
      state.selectedProducts
    )
    dispatch(setAllProducts(mappedProducts))
    setShowEdit(isEdit)
  }

  //ALL PRODUCTS API
  const getAllProducts = async (companyId, parentLevel) => {
    try {
      setLoader(true)
      let response
      if (state.currentCompanyDetails != null) {
        dispatch(setSelectedProducts(state.currentCompanyDetails?.products))
      }
      if (!searchParams.get('company_id') && currentLevel == 'master') {
        response = await masterProductsAllApi(companyId, 'master')
      } else {
        response = await genericProductsAllApi(companyId, currentLevel)
      }
      setShowEditButton(true)
      dispatch(setAllProducts(response.data.result))
      setLoader(false)
    } catch (error) {
      setLoader(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const mapProducts = async (allProducts, selectedProducts) => {
    const all = allProducts.map((res) => {
      return {
        id: res.id,
        name: res?.name,
        description: res.description,
        iconUrl: res.iconUrl,
        productCode: res.productCode,
        checked: false,
      }
    })
    const checkedArray = await all.map((item) => {
      let index = removeDuplicates(selectedProducts).find(
        (ser) => ser.id === item.id
      )
      if (index) {
        item.checked = true
      }
      return item
    })
    return checkedArray
  }

  const handleCheckBox = (e, ind) => {
    const mapSelectedProduct = (index, value) => {
      let newState = [...state.allProducts]
      newState[index] = {
        ...newState[index],
        checked: value,
      }
      return newState
    }
    dispatch(setAllProducts(mapSelectedProduct(ind, e.target.checked)))
  }
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      {!state.selectedProducts || loader ? (
        <AvailableServicesSkeleton />
      ) : (
        <>
          <Paper elevation={6} style={{ height: '530px' }} className="paper1">
            <Box>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 0 0 20px',
                }}
              >
                <div style={{ fontSize: '16px', fontWeight: '600' }}>
                  Available Services
                </div>
                {showEditButton &&
                  !showEdit &&
                  token?.company_level != COMPANY_LEVELS.customer && (
                    <div
                      style={{
                        padding: '0px 25px 0 0',
                      }}
                    >
                      <IconButton
                        onClick={() => handleEditServices(true)}
                        aria-label="delete"
                        style={{
                          float: 'right',
                          borderRadius: 0,
                          fontSize: '15px',
                          marginTop: '-3px',
                        }}
                      >
                        <EditIcon
                          style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            margin: '0 0px 0 10px',
                          }}
                        />
                        Edit
                      </IconButton>
                    </div>
                  )}
              </div>
              <Box container style={{ marginTop: '20px' }}>
                <form onSubmit={formik.handleSubmit}>
                  <Box md={12} sm={12} xs={12}>
                    <List>
                      {!showEdit ? (
                        <>
                          {removeDuplicates(state.selectedProducts || [])?.map(
                            (dat, index) => (
                              <ListItem disablePadding key={index}>
                                <ListItemButton>
                                  <ListItemAvatar>
                                    {dat.productCode ===
                                    SERVICE_PRODUCT_CODES.SipTrunking ? (
                                      <StyledAvatar
                                        src={sipLogo}
                                      ></StyledAvatar>
                                    ) : dat.productCode ===
                                      SERVICE_PRODUCT_CODES.DirectRouting ? (
                                      <StyledAvatar src={drLogo}></StyledAvatar>
                                    ) : dat.productCode ===
                                      SERVICE_PRODUCT_CODES.MaxCloudCoreView ? (
                                      <StyledAvatar
                                        src={maxucLogo}
                                      ></StyledAvatar>
                                    ) : (
                                      <Avatar
                                        {...stringAvatar(
                                          dat.productCode ===
                                            SERVICE_PRODUCT_CODES.DirectRouting
                                            ? PRODUCT_NAMES.TPS
                                            : dat?.name
                                        )}
                                      ></Avatar>
                                    )}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      dat.productCode ===
                                      SERVICE_PRODUCT_CODES.DirectRouting
                                        ? PRODUCT_NAMES.TPS
                                        : dat?.name
                                    }
                                  />
                                </ListItemButton>
                              </ListItem>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {editLoader ? (
                            <SkeletonTableLoader></SkeletonTableLoader>
                          ) : (
                            <>
                              {removeDuplicates(state.allProducts)?.map(
                                (dat, index) => (
                                  <ListItem disablePadding key={index + '_'}>
                                    <ListItemButton>
                                      <FormControlLabel
                                        key={index}
                                        label={
                                          dat.productCode ===
                                          SERVICE_PRODUCT_CODES.DirectRouting
                                            ? PRODUCT_NAMES.TPS
                                            : dat?.name
                                        }
                                        control={
                                          <FlexBox>
                                            <Checkbox
                                              checked={dat?.checked}
                                              onChange={(e) =>
                                                handleCheckBox(e, index)
                                              }
                                            />
                                            {dat.productCode ===
                                              SERVICE_PRODUCT_CODES.DirectRouting && (
                                              <StyledAvatar
                                                width={45}
                                                height={45}
                                                src={drLogo}
                                              ></StyledAvatar>
                                            )}
                                            {dat.productCode ===
                                              SERVICE_PRODUCT_CODES.MaxCloudCoreView && (
                                              <StyledAvatar
                                                width={45}
                                                height={45}
                                                src={maxucLogo}
                                              ></StyledAvatar>
                                            )}
                                            {dat.productCode ===
                                              SERVICE_PRODUCT_CODES.SipTrunking && (
                                              <StyledAvatar
                                                width={45}
                                                height={45}
                                                src={sipLogo}
                                              ></StyledAvatar>
                                            )}
                                          </FlexBox>
                                        }
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                )
                              )}
                            </>
                          )}
                        </>
                      )}
                    </List>
                  </Box>
                  {showEdit ? (
                    <Box
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      className="stepper-input-label-margin"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => handleEditServices(false)}
                        type="button"
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </form>
              </Box>
            </Box>
          </Paper>
        </>
      )}
    </>
  )
}
export default MasterServices
