import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  Avatar,
  Chip,
  Container,
  Drawer,
  InputBase,
  Link,
  Stack,
  TableHead,
  Tooltip,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import KeyIcon from '@mui/icons-material/Key'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import AddNewUser from './addNewUser'
import { useEffect, useState } from 'react'
import ViewUser from './viewUser'
import EditUserDetails from './editUserDetails'
import EditUserRoleDetails from './editUserRoleDetails'
import {
  masterUserDeleteApi,
  masterUserListApi,
} from '../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import { useLocation, useSearchParams } from 'react-router-dom'
import ConfirmationModal from '../../../common/components/custom/modal/modal'
import LoadingSection from '../../../common/components/loaders/loader'
import NoDataFoundCard from '../../../common/components/cards/noDataFoundCard'
import ReLoginPage from './relogin'
import { createRef } from 'react'
import ResetPasswordModal from '../../../common/components/custom/modal/ResetPasswordModal'
import { setUsersList } from '../../../store/slice/userManagementSlice'
import {
  checkPermission,
  getCompanyLevel,
} from '../../../helpers/HelperFunctions'
import { PERMISSIONS } from '../../../helpers/Constants'
import CancelIcon from '@mui/icons-material/Cancel'
import { Add as AddIcon } from '@mui/icons-material'
import DrawerHeader from '../../../common/components/drawer/DrawerHeader'
import { useUserManagementRedux } from '../UserManagement'
import ThemeButton from '../../../common/components/button/Button'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'

function UsersTable(props) {
  const { dispatch, state: commonState } = useCommonRedux()
  const { state: _state } = useUserManagementRedux()
  const { toast } = useToastContext()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [rowsList, setRowsList] = React.useState([])
  const [edited, setEdited] = React.useState('')
  const [userDetails, setUserDetails] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [resetDetails, setResetDetails] = React.useState()
  const [searchParams] = useSearchParams()
  const [state, setState] = React.useState({
    right: false,
  })
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [confirmReset, setConfirmReset] = useState(false)
  const pageTopRef = createRef()
  let inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase()
    const filteredData = _state.usersList.filter((el) => {
      if (lowerCase === '') {
        return el
      } else {
        return (
          el?.firstName.toLowerCase().includes(lowerCase) ||
          el?.lastName.toLowerCase().includes(lowerCase) ||
          el?.phone?.toString().includes(lowerCase) ||
          el?.email.toLowerCase().includes(lowerCase)
        )
      }
    })
    setRowsList(filteredData)
    setPage(0)
  }
  const handleClose = () => {
    setIsOpen(false)
    setConfirmReset(false)
  }
  const handleDelete = (id) => {
    setSubmitLoader(true)
    masterUserDeleteApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setSubmitLoader(false)
        toast.showToast(response.data.message, 'success')
        getDecodeValue()
        handleClose()
        toggleDrawer('right', false)
      })
      .catch((error) => {
        setSubmitLoader(false)
        toast.showToast( normalizeApiResponse(error)?.message, 'error')
      })
  }
  const getUserList = (id) => {
    setLoader(true)
    masterUserListApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        setLoader(false)
        const sortedData = response.data.result.sort((a, b) =>
          a.firstName.localeCompare(b.firstName)
        )
        dispatch(setUsersList(sortedData))
        setRowsList(sortedData)
      })
      .catch((error) => {
        setLoader(false)
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      })
  }

  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    setResetDetails(decoded)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getUserList(companyId)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageTopRef.current.scrollTop = 0
  }
  const gotoDrawer = (event, details) => {
    if (details) {
      setUserDetails(details)
    }
    setEdited(event)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value))
    setPage(0)
    pageTopRef.current.scrollTop = 0
  }

  const toggleDrawer = (anchor, open, switchVal, details, load) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //     return;
    // }
    if (load) {
      getDecodeValue()
    }
    setUserDetails(details)
    setEdited(switchVal)
    setState({ ...state, [anchor]: open })
  }

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - rowsList?.length)
      : 0

  useEffect(() => {
    getDecodeValue()
  }, [])

  const checkAddEdit = (item, index) => {
    switch (item) {
      case 'add':
        return <AddNewUser toggleDrawer={toggleDrawer} />
      case 'view':
        return (
          <>
            <ViewUser userId={userDetails} gotoDrawer={gotoDrawer}></ViewUser>
          </>
        )
      case 'editRole':
        return (
          <>
            <EditUserRoleDetails
              toggleDrawer={toggleDrawer}
              userId={userDetails}
              gotoDrawer={gotoDrawer}
            ></EditUserRoleDetails>
          </>
        )
      case 'editUser':
        return (
          <>
            <EditUserDetails
              toggleDrawer={toggleDrawer}
              userId={userDetails}
              gotoDrawer={gotoDrawer}
            ></EditUserDetails>
          </>
        )
    }
  }

  return (
    <Container maxWidth="xl">
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <React.Fragment>
        <Drawer
          PaperProps={{
            sx: { width: '25%' },
          }}
          variant="persistent"
          hideBackdrop
          anchor={'right'}
          open={state['right']}
        >
          <div>
            {checkPermission(
              [
                PERMISSIONS.masterUserUpdate,
                PERMISSIONS.serviceproviderUserUpdate,
                PERMISSIONS.resellerUserUpdate,
                PERMISSIONS.customerUserUpdate,
              ],
              commonState.currentCompanyDetails
            ) &&
              (edited == 'editRole' || edited == 'editUser' ? (
                <Tooltip title="Back">
                  <IconButton
                    aria-label="back"
                    onClick={() => gotoDrawer('view')}
                    style={{
                      marginLeft: '-2px',
                      float: 'left',
                      borderRadius: 0,
                    }}
                  >
                    <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              ))}
            {edited != 'add' && (
              <Tooltip title="Close">
                <IconButton
                  aria-label="delete"
                  onClick={() => toggleDrawer('right', false)}
                  style={{ float: 'right', borderRadius: 0 }}
                >
                  <CancelIcon color="error" size="large"></CancelIcon>
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div>
            {edited == 'view' ? (
              <h3
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  marginLeft: '10px',
                  marginBottom: '0',
                }}
              >
                <Avatar sx={{ bgcolor: 'skyblue' }}>
                  {' '}
                  {userDetails?.firstName?.slice(0, 1)}{' '}
                </Avatar>
                <div style={{ marginLeft: '10px' }}>
                  {userDetails?.firstName} {userDetails?.lastName}
                  <div style={{ display: 'flex' }}>
                    {checkPermission(
                      [
                        PERMISSIONS.masterUserUpdate,
                        PERMISSIONS.serviceproviderUserUpdate,
                        PERMISSIONS.resellerUserUpdate,
                        PERMISSIONS.customerUserUpdate,
                      ],
                      commonState.currentCompanyDetails
                    ) && resetDetails?.is_primary_contact ? (
                      <div
                        style={{
                          fontSize: '15px',
                          color: ' #000000a1',
                          cursor: 'pointer',
                        }}
                        onClick={() => setConfirmReset(true)}
                      >
                        <KeyIcon
                          style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            margin: '0 5px 0 0',
                          }}
                        ></KeyIcon>
                        Reset Password
                      </div>
                    ) : (
                      ''
                    )}
                    {checkPermission(
                      [
                        PERMISSIONS.masterUserDelete,
                        PERMISSIONS.serviceproviderUserDelete,
                        PERMISSIONS.resellerUserDelete,
                        PERMISSIONS.customerUserDelete,
                      ],
                      commonState.currentCompanyDetails
                    ) ? (
                      <div
                        style={{
                          fontSize: '15px',
                          color: ' #000000a1',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsOpen(true)}
                      >
                        <PersonRemoveIcon
                          style={{
                            fontSize: '12px',
                            verticalAlign: 'middle',
                            margin: '0 5px 0 15px',
                          }}
                        ></PersonRemoveIcon>
                        Delete User
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </h3>
            ) : (
              ''
            )}
            {edited == 'editRole' || edited == 'editUser' ? (
              <>
                <h3
                  style={{
                    display: 'flex',
                    marginTop: '-5px',
                    marginBottom: '0',
                  }}
                >
                  {/*{edited == 'editRole' ? <Avatar sx={{ bgcolor: 'skyblue' }}>J</Avatar>: ''}*/}
                  <div style={{ marginLeft: '10px' }}>
                    {edited == 'editRole' ? 'Assign Role' : ''}
                    {edited == 'editUser' ? 'Update User' : ''}
                    <div style={{ display: 'flex' }}>
                      <div style={{ fontSize: '15px', color: ' #000000a1' }}>
                        {edited == 'editRole'
                          ? 'Update roles of ' + userDetails.email
                          : ''}
                        {edited == 'editUser' ? 'Update user details' : ''}
                      </div>
                      {/*<div style={{ fontSize:'15px', color:' #000000a1',}}>*/}
                      {/*    <PersonRemoveIcon  style={{fontSize:'12px',verticalAlign:'middle', margin: '0 5px 0 15px'}}></PersonRemoveIcon>*/}
                      {/*    Delete User*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </h3>
              </>
            ) : (
              ''
            )}
            {edited == 'add' ? (
              <>
                <DrawerHeader
                  header="Add New User"
                  onClick={() => toggleDrawer('right', false)}
                />
              </>
            ) : (
              ''
            )}
          </div>
          {checkAddEdit(edited)}
          <ConfirmationModal
            handleConfirm={() => handleDelete(userDetails?.id)}
            title="Delete User Confirmation"
            content={
              <span>
                Are you sure you want to delete this user{' '}
                <b>{userDetails?.email}</b>?
              </span>
            }
            isOpen={isOpen}
            handleClose={handleClose}
          />

          <ResetPasswordModal
            title="Reset Password"
            topValue={'25%'}
            content={
              <ReLoginPage
                userDetails={userDetails}
                resetDetails={resetDetails}
                handleClose={handleClose}
              />
            }
            isOpen={confirmReset}
            hideActionsBtn={true}
            handleClose={handleClose}
          />
        </Drawer>
      </React.Fragment>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0',
        }}
      >
        <Paper
          // component="div"
          sx={{
            p: '2px 4px',
            m: '10px 0',
            display: 'flex',
            alignItems: 'center',
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            onChange={inputHandler}
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        {checkPermission(
          [
            PERMISSIONS.masterUserCreate,
            PERMISSIONS.serviceproviderUserCreate,
            PERMISSIONS.resellerUserCreate,
            PERMISSIONS.customerUserCreate,
          ],
          commonState.currentCompanyDetails
        ) ? (
          <Tooltip title="Add New User">
            <ThemeButton
              text={'Add New User'}
              variant="contained"
              size="medium"
              style={{ height: '32px', marginTop: '20px' }}
              startIcon={<AddIcon />}
              onClick={() => toggleDrawer('right', true, 'add')}
            ></ThemeButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Box>
      <TableContainer
        sx={{
          height:
            rowsPerPage == 5 || rowsList?.length <= 7
              ? 'max-content'
              : 'calc(95vh - 200px)',
        }}
        component={Paper}
        ref={pageTopRef}
      >
        <Table
          stickyHeader
          sx={{ minWidth: 500 }}
          aria-label="custom pagination table"
        >
          <TableHead className="thead">
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>FullName</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Assigned Roles</TableCell>
              {/*<TableCell >#</TableCell>*/}
            </TableRow>
          </TableHead>
          {loader ? (
            <TableBody className="tbody">
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6}>
                  <SkeletonTableLoader></SkeletonTableLoader>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody className="tbody">
              {(rowsPerPage > 0
                ? rowsList?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : rowsList
              )?.map((row, index) => (
                <TableRow hover key={index}>
                  <TableCell width={'10px'}>
                    {page * rowsPerPage + (index + 1)}
                  </TableCell>
                  <TableCell
                    scope="row"
                    onClick={() => toggleDrawer('right', true, 'view', row)}
                  >
                    <Link> {`${row.firstName} ${row.lastName}`} </Link>
                  </TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {row?.roles?.map((text, index) => (
                        <Chip
                          key={index}
                          label={text.roleName}
                          variant="outlined"
                        />
                      ))}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {rowsList?.length === 0 ? (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} style={{ border: 'none' }}>
                    <h3
                      style={{
                        margin: '0px auto',
                        textAlign: 'center',
                      }}
                    >
                      <NoDataFoundCard
                        messages="No Users found"
                        borderNone={true}
                      />
                    </h3>
                  </TableCell>
                </TableRow>
              ) : (
                ''
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={rowsList?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  )
}
export default UsersTable
